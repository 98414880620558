import React from "react"

import Box from "../../components/box"
import Breadcrumb from "../../components/breadcrumb"
import Button from "../../components/button"
import Column from "../../components/column"
import Columns from "../../components/columns"
import Divider from "../../components/divider"
import Fuehrung from "../../content/shared/fuehrung"
import FuehrungenKontakt from "../../content/shared/fuehrungen-kontakt"
import Heading from "../../components/heading"
import Inline from "../../components/inline"
import Layout from "../../layouts/default"
import PageTitle from "../../components/page-title"
import Paragraph from "../../components/paragraph"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import Text from "../../components/text"
import TextLink from "../../components/textlink"
import Trivia from "../../components/trivia"

const FuehrungenPage = () => {
  return (
    <Layout backdrop="fuehrungen">
      <Seo
        title="Führungen"
        description="Zur Dauerausstellung gibt es ein vielfältiges Angebot aus Führungen und Workshops. Ob ›Kurz und bündig‹ oder ›Einmal alles‹, ob auf deutsch, englisch, französisch oder in Leichter Sprache – hier ist für jeden etwas dabei."
      />

      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Führungen",
              link: "/fuehrungen",
            },
          ]}
        />
        <Stack space={12}>
          <PageTitle>Führungen</PageTitle>
          <Columns collapse={[true, true, false]} space={[6, 12]}>
            <Column>
              <Stack space={[12, 24]}>
                <Trivia title="Bitte beachten Sie">
                  <Paragraph size={[2, 3]}>
                    Die maximale Gruppengröße liegt bei 15 Personen.
                  </Paragraph>
                </Trivia>

                <Stack space={6}>
                  <Heading as="h2" level={4}>
                    Sonntagsführungen durch die Dauerausstellung
                  </Heading>
                  <Paragraph size={[2, 3, 4]}>
                    Jeden 1. und 3. Sonntag im Monat bieten wir um 14 Uhr eine
                    öffentliche Führung durch die{" "}
                    <TextLink to="/ausstellungen/dauerausstellung">
                      Dauerausstellung
                    </TextLink>{" "}
                    an. Die Anmeldung erfolgt an der Museumskasse. Eine
                    telefonische Voranmeldung ist während der{" "}
                    <TextLink to="/kontakt#oeffnungszeiten">
                      Museumsöffnungszeiten
                    </TextLink>{" "}
                    unter 07071 204 1862 möglich.
                  </Paragraph>
                  <Inline alignY="center" space={6}>
                    <Text color="whisper" sans size={3}>
                      60 Minuten
                    </Text>
                    <Text color="whisper" sans size={3}>
                      7 € / 5 € ermäßigt
                    </Text>
                    <Button secondary to="/fuehrungen/sonntagsfuehrungen">
                      Mehr erfahren
                    </Button>
                  </Inline>
                </Stack>
                <Divider size={4} />

                <Heading as="h2" level={3}>
                  Angebote für Gruppen
                </Heading>
                <Fuehrung
                  headingElement="h3"
                  title="Kurz und bündig"
                  description={`Eine Einführung stellt den Hölderlinturm sowie die Themen seiner Dauerausstellung vor und verweist auf einige Highlights. Im Anschluss kann der Ausstellungsrundgang ganz individuell und auf eigenen Wegen fortgesetzt werden.`}
                  duration="20 Minuten"
                  price="30 €"
                  bookable={true}
                />
                <Fuehrung
                  headingElement="h3"
                  title="Einmal alles"
                  description={`Ein allgemeiner Rundgang führt durch alle Räume der Dauerausstellung und gibt Einblick in Hölderlins Leben und Werk, insbesondere seine frühen Jahre im Tübinger Stift und seine späten Jahre im Turm am Neckar. Darüber hinaus wird Hölderlins Wirkung auf die Literatur, Musik und Kunst bis heute mitverfolgt und den stilistischen Eigenheiten seiner Gedichte nachgespürt.`}
                  duration="60 Minuten"
                  price="60 €"
                  bookable={true}
                />
                <Fuehrung
                  headingElement="h3"
                  title="Der Hölderlinturm und seine Geschichte"
                  description={`Die Geschichte des Hölderlinturms reicht um einiges weiter zurück als die seines Namensgebers. Die ältesten baulichen Bestandteile des Gebäudes stammen noch aus dem Mittelalter und waren Teil der Stadtbefestigung. Im Laufe der Jahre wandelte sich die Nutzung des Gebäudes: Vom Färbereibetrieb zum Wohnhaus, von der Schreinerwerkstatt zur Badeanstalt. Anhand der baulichen Überreste wird diese Geschichte nachverfolgt.`}
                  duration="60 Minuten"
                  price="60 €"
                  bookable={true}
                />
                <Fuehrung
                  title="Hölderlin in den Künsten"
                  description={`Nicht nur Schriftstellerinnen und Schriftsteller haben Hölderlin Gedichte gewidmet, seine Biografie im Drama oder Roman aufgearbeitet und seine Verse zum Ausgangspunkt neuer Werke gemacht. Auch Komponisten wie Johannes Brahms, Benjamin Britten, Hanns Eisler und Luigi Nono sowie bildende Künstlerinnen und Künstler haben sich von ihm zu eigenen Werken inspirieren lassen. Hölderlins Wirkungsgeschichte wird so zu einem Gang durch die Literatur-, Musik- und Kunstgeschichte und zeigt, wie der Dichter und sein Werk im Laufe der Jahrhunderte interpretiert und aufgegriffen wurden.`}
                  duration="60 Minuten"
                  price="60 €"
                  bookable={true}
                />

                <Stack space={6}>
                  <Heading as="h2" level={4}>
                    App zur Dauerausstellung
                  </Heading>
                  <Paragraph size={[2, 3, 4]}>
                    Unsere Media-Guide-App nimmt Sie mit auf eine Tour durch
                    unsere Dauerausstellung. Erfahren Sie spannende Hintergründe
                    zu den Objekten, Räumen und Themen. Mit Hör- und Lese-Texten
                    in Deutsch, Englisch, Französisch und Leichter Sprache,
                    Videos in Gebärdensprache und Beschreibungen für blinde und
                    sehbeeinträchtigte Menschen.
                  </Paragraph>
                  <Inline space={3}>
                    <Button
                      secondary={true}
                      href="https://play.google.com/store/apps/details?id=de.droidsolutions.tuebingen"
                    >
                      Google Play Store
                    </Button>
                    <Button
                      secondary={true}
                      href="https://apps.apple.com/de/app/literaturpfad-t%C3%BCbingen/id1471621109"
                    >
                      App Store
                    </Button>
                  </Inline>
                </Stack>
              </Stack>
            </Column>
            <Column width={["full", "full", "2/5"]}>
              <Box position={["static", "sticky"]} top={6}>
                <FuehrungenKontakt />
              </Box>
            </Column>
          </Columns>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default FuehrungenPage
